import React from 'react'
import backgroundImage from '../../assets/images/gelato.jpg'
import { Link as ScrollLink } from 'react-scroll'
// import { Link } from 'gatsby'

const Gelato = props => (
  <section id="gelato" className="spotlight style4 left" style={{ backgroundImage: `url(${backgroundImage})` }}>
    <span className="image fit main">
      <img src={backgroundImage} alt="" />
    </span>
	<div className="content">
		<header>
			<h2>Gelato</h2>
			<p>Gelato by Campfire Pizza and Gelato</p>
		</header>
		{/* <ul className="actions">
			<li>
			<Link to="/gelato" className="button">View Gelato</Link>
			</li>
		</ul> */}
	</div>
	<ScrollLink to="visit" className="goto-next" activeClass="active" smooth={true} offset={0} duration={750} spy={true}>Next</ScrollLink>
  </section>
)

export default Gelato