import React from 'react'
import backgroundImage from '../../assets/images/roberta-sorge-IywM7AQTZcM-unsplash-cropped.jpg'
import { Link as ScrollLink } from 'react-scroll'
// import { Link } from 'gatsby'

const Wine = props => (
  <section id="wine" className="spotlight style2 left" style={{ backgroundImage: `url(${backgroundImage})` }}>
    <span className="image fit main">
      <img src={backgroundImage} alt="" />
    </span>
    <div className="content">
		<header>
			<h2>Wine, Beer, &amp; Spirits</h2>
			<p>A great selection of local and international wines</p>
			{/* <h2>Wine</h2>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent feugiat sit amet sem vitae ultrices. Nullam eleifend felis non consectetur ultricies.</p>
			<h2>Beer</h2>
			<p>Proin vel consectetur ligula, nec lobortis elit. Integer iaculis, risus nec bibendum imperdiet, leo est rhoncus elit, in scelerisque tellus sem eu dolor.</p>
			<h2>Liquor</h2>
			<p>Maecenas luctus gravida velit, sit amet sodales erat. Sed auctor arcu id nibh tincidunt mollis. Vestibulum tempor aliquam elit, nec gravida nibh accumsan nec.</p> */}
		</header>
		{/* <ul className="actions">
			<li>
				<Link to="/wine" className="button">View Wine and Spirits Menu</Link>
			</li>
		</ul> */}
    </div>
    <ScrollLink to="pastries" className="goto-next" activeClass="active" smooth={true} offset={0} duration={750} spy={true}>Next</ScrollLink>
  </section>
)

export default Wine