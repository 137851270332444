import React from 'react'
// import pic01 from '../../assets/images/pic01.jpg'
import { Link } from 'gatsby'
import { Link as ScrollLink } from 'react-scroll'

import { locationData } from '../../assets/locationData.js'

export default function Banner(props)
{
	const locations = locationData.locations.map((location) => {
		return <p><Link className="button fit secondary" to={location.id}>{location.name}</Link></p>;
	});

	return (
		<section id="banner">
			<div className="content">
				<header>
					<h2>Two locations<br />in Edmond</h2>
					{locations}
				</header>
				{/* <span className="image">
					<img src={pic01} alt="intro" />
				</span> */}
			</div>
			<ScrollLink to="gallery" className="goto-next" activeClass="active" smooth={true} offset={0} duration={750} spy={true}>Next</ScrollLink>
		</section>
	);
}
