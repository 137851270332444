import React from 'react'
import Layout from '../components/layout'
import EllisHelmet from '../components/EllisHelmet'
import Banner from '../components/home/Banner'
import Gallery from '../components/home/Gallery'
import Coffee from '../components/home/Coffee'
import Wine from '../components/home/Wine'
import Food from '../components/home/Food'
import Gelato from '../components/home/Gelato'
import Visit from '../components/home/Visit'

class Home extends React.Component {
  render() {
    return (
      <Layout location="/">
		<EllisHelmet page={null} title={null} location={null} />
        <Banner />
		<Gallery />
        <Coffee />
        <Wine />
        <Food />
        <Gelato />
        <Visit />
      </Layout>
    )
  }
}

export default Home