import React from 'react'
import backgroundImage from '../../assets/images/pastries.jpg'
import { Link as ScrollLink } from 'react-scroll'
// import { Link } from 'gatsby'

const Food = props => (
  <section id="pastries" className="spotlight style3 right" style={{ backgroundImage: `url(${backgroundImage})` }}>
    <span className="image fit main bottom">
      <img src={backgroundImage} alt="" />
    </span>
	<div className="content">
		<header>
			<h2>Food &amp; Pastries</h2>
			<p>La Baguette pastries, cakes, and much much more.</p>
		</header>
		{/* <ul className="actions">
			<li>
			<Link to="/labaguette-desserts" className="button">View La Baguette Pastries</Link>
			</li>
		</ul> */}
	</div>
    <ScrollLink to="gelato" className="goto-next" activeClass="active" smooth={true} offset={0} duration={750} spy={true}>Next</ScrollLink>
  </section>
)

export default Food