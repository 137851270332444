import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link as ScrollLink } from 'react-scroll'
import { formatDatoCMSData } from '../../services/datoCMS.service'

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/scale-out-animation.css';
import '../../assets/scss/customAwesomeSlider.scss';

export default function Gallery()
{
	const query = useStaticQuery(graphql`
  query homeHeroGalleryQuery {
    allDatoCmsHeroGallery(sort: {fields: position, order: ASC}) {
    nodes {
      image {
        url(imgixParams: {auto: "format,compress,redeye", fit: "max", w: "2500", h: "1875"})
      }
    }
  }
  }`);

	const galleryData = formatDatoCMSData(query);

	const sliderimages = galleryData.nodes.map((image) => <div data-src={image.image.url} />);

	return (
		// <section id="gallery" className="spotlight top">
		<section id="gallery">
			<div className="content">
				<AwesomeSlider animation="scaleOutAnimation">
					{sliderimages}
				</AwesomeSlider>
			</div>
			<ScrollLink to="coffee" className="goto-next" activeClass="active" smooth={true} offset={0} duration={750} spy={true}>Next</ScrollLink>
		</section>
	);
}