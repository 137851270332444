import React from 'react'
import { Link } from 'gatsby'

import { locationData } from '../../assets/locationData.js'

export default function Visit(props)
{
	const locations = locationData.locations.map((location) =>
	{
		const url = '/' + location.id;
		const title = <h3>{location.name}</h3>;
		// const inlocation = <h4>in {location.address.city}</h4>;
		const picture = (location.picture ? <img className="location" src={location.picture} alt={location.name} /> : null);
		const visitButton = <Link className="button primary" href={url}>Visit</Link>;

		return (<div className="col-4 col-12-medium centered">
				{title}
				<Link to={url}>{picture}</Link>
				{visitButton}
			</div>);
	});

	return (
		<section id="visit">
			<header className="major">
				<h2>{locationData.locations.length} Locations To Choose From</h2>
			</header>
			<div className="box alt">
				<div className="row">
					{locations}
				</div>
			</div>
		</section>
	);
}