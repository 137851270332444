import React from 'react'
import backgroundImage from '../../assets/images/coffee.jpg'
import { Link as ScrollLink } from 'react-scroll'
// import { Link } from 'gatsby'

const Coffee = props => (
  <section id="coffee" className="spotlight style1 right" style={{ backgroundImage: `url(${backgroundImage})` }}>
    <span className="image fit main bottom">
      <img src={backgroundImage} alt="" />
    </span>
	<div className="content">
		<header>
			<h2>Coffee</h2>
			<p>Live local!</p>
			<p>Coffee and espresso roasted with love by Eote Coffee</p>
			{/* <h2>Not Coffee</h2>
			<p>Ipsum dolor feugiat aliquam tempus sed magna lorem consequat accumsan</p> */}
		</header>
	</div>
	{/* <div className="col-3 col-12-medium">
		<p>
		</p>
	</div> */}
    <ScrollLink to="wine" className="goto-next" activeClass="active" smooth={true} offset={0} duration={750} spy={true}>Next</ScrollLink>
  </section>
)

export default Coffee